import React from 'react';
import cx from 'classnames';

import { BlockWrapper } from '../blockWrapper';
import { PortableTextBlock } from '../../sanity/portableTextBlock';

const TextBlock = ({ _rawText, centerText, blockConfig, layout }) => {
  return (
    <BlockWrapper className="grid grid-cols-14" {...blockConfig}>
      <div
        className={cx('prose lg:prose-lg max-w-none col-start-2 col-end-14', {
          'text-center lg:col-start-3 lg:col-end-13 xl:col-start-4 xl:col-end-12': centerText,
          'lg:col-start-3 lg:col-end-13': layout === 'center',
        })}
      >
        <PortableTextBlock text={_rawText} />
      </div>
    </BlockWrapper>
  );
};

export default TextBlock;
