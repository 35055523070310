import React from 'react';
import { motion } from 'framer-motion';
import cx from 'classnames';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useLocation } from '@reach/router';

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
}

export const SignUpForm = () => {
  const { pathname } = useLocation();

  return (
    <Formik
      initialValues={{
        email: '',
      }}
      validate={(values) => {
        const errors = {};

        if (!values.email) {
          errors.email = 'Email address is Required';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
          errors.email = 'Invalid email address';
        }
        return errors;
      }}
      onSubmit={(values, { setSubmitting, setStatus }) => {
        fetch(pathname || '/', {
          method: 'POST',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          body: encode({
            'form-name': `signup-form`,
            ...values,
          }),
        })
          .then(() => {
            setSubmitting(false);
            setStatus('success');
          })
          .catch((error) => alert(error));
      }}
    >
      {({
        onChange,
        status,
        isSubmitting,
        /* and other goodies */
      }) => (
        <Form
          className="py-10"
          name="signup-form"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          subject="Pomelo Enquiry"
        >
          {/* Hidden Fields for netlify */}
          <input type="hidden" name="form-name" value="signup-form" />
          <p hidden>
            <label>
              <input name="bot-field" onChange={onChange} />
            </label>
          </p>
          {status === 'success' ? (
            <div className="col-span-full text-left">
              <motion.h2
                className="font-display text-2xl text-peach-100 text-center"
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
              >
                Thank you we will be in touch.
              </motion.h2>
            </div>
          ) : (
            <>
              <div className="flex flex-col gap-y-10 md:flex-row md:gap-x-4 md:max-w-xl mx-auto">
                <div className="md:flex-grow">
                  <Field
                    type="email"
                    name="email"
                    className="rounded-md bg-white w-full py-4 px-2"
                    placeholder="Email Address"
                  />
                  <ErrorMessage name="email" className="text-sm" component="div" />
                </div>
                <button
                  type="submit"
                  className={cx('btn', { 'animate-pulse': isSubmitting })}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? 'Sending' : 'Submit'}
                </button>
              </div>
            </>
          )}
        </Form>
      )}
    </Formik>
  );
};
