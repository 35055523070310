import React, { useState } from 'react';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import cx from 'classnames';

import { motion, useTransform } from 'framer-motion';

import ExitIcon from '../../../svg/exit.svg';

import { ImageModule } from '../../modules/imageModule';
import { BlockWrapper } from '../blockWrapper';
import { PortableTextBlock } from '../../sanity/portableTextBlock';
import { StandardModal } from '../../ui/standardModal';
import { ScrollTriggerProvider, useScrollTrigger } from '../../animation/scrollTriggerProvider';

const BiosMobile = ({ bios }) => {
  return (
    <div className="grid gap-y-10 px-gutter lg:grid-cols-3 lg:gap-x-10">
      {bios.map(({ title, text, image }) => (
        <div>
          {/* Image */}
          <div className="relative">
            <div className="aspect-w-4 aspect-h-5 relative rounded-3xl overflow-hidden">
              <ImageModule
                {...image}
                className="absolute top-0 left-0 w-full h-full object-cover"
              />
            </div>
            <h4 className="isH2 absolute bottom-6 left-6 text-white">{title}</h4>
          </div>
          {/* Text */}
          <PortableTextBlock text={text} className="prose prose-lg pt-10" />
        </div>
      ))}
    </div>
  );
};

const BioCardDesk = ({ image, hoverImage, title, text, _key, expanded, setExpanded, index }) => {
  const isOpen = _key === expanded;
  const isEven = index % 2 == 0;
  const position = useScrollTrigger();
  const opacity = useTransform(position, [0, 1], [0, 1]);
  const y = useTransform(position, [0, 1], [isEven ? 100 : 50, 0]);
  return (
    <>
      <motion.div
        className={cx('w-1/3 relative cursor-pointer group', {
          'mt-32': isEven,
        })}
        onClick={() => setExpanded(isOpen ? false : _key)}
        style={{ opacity, y, willChange: 'scroll-position' }}
      >
        <svg
          class="svg"
          className="w-full absolute top-0 right-0 object-cover"
          viewBox="0 0 460 460.07"
        >
          <clipPath id={`my-clip-path-${_key}`} clipPathUnits="objectBoundingBox">
            <path d="M0.226,1 c-0.12,0.059,-0.249,-0.064,-0.193,-0.184 L0.361,0.099 A0.144,0.144,0,0,1,0.591,0.057 L0.977,0.425 a0.14,0.14,0,0,1,-0.036,0.227"></path>
          </clipPath>
        </svg>
        <div className="aspect-w-4 aspect-h-5 relative rounded-3xl overflow-hidden bg-mint-75">
          <ImageModule
            {...image}
            className="absolute top-0 left-0 object-cover w-full h-full"
            style={{
              clipPath: `url(#my-clip-path-${_key})`,
            }}
          />
          <ImageModule
            {...image}
            className="absolute top-0 left-0 object-cover w-full h-full transition-opacity duration-300 opacity-100 group-hover:opacity-0"
          />
        </div>
        <h4 className="isH3 absolute bottom-6 left-6 text-white">{title}</h4>
      </motion.div>
      <StandardModal isOpen={isOpen}>
        <div className="bg-peach-100 text-white max-w-4xl mx-auto p-10 grid  gap-y-10 rounded-3xl relative grid-cols-2 gap-x-10 self-center">
          <div className="aspect-w-4 aspect-h-5 relative rounded-3xl overflow-hidden group">
            {hoverImage && (
              <ImageModule
                {...hoverImage}
                className="absolute inset-0 object-cover z-10 opacity-0 group-hover:opacity-100 transition-opacity duration-300"
              />
            )}
            {image && <ImageModule {...image} className="absolute inset-0 object-cover" />}
          </div>
          <div>
            <div className="flex justify-between items-center border-b-2 border-white mb-4 py-4">
              <h4 className="isH3 mb-2">{title}</h4>
              <button
                className="text-white focus:outline-none outline-none"
                onClick={() => setExpanded(0)}
              >
                <ExitIcon className="w-6 fill-current" />
              </button>
            </div>
            <PortableTextBlock text={text} className="prose" />
          </div>
        </div>
      </StandardModal>
    </>
  );
};

const BiosDesk = ({ bios }) => {
  const [expanded, setExpanded] = useState(0);
  return (
    <div className="px-gutter py-20 w-full overflow-hidden">
      <div className="flex gap-x-10 justify-center items-center">
        {bios.map((item, index) => (
          <BioCardDesk {...item} expanded={expanded} setExpanded={setExpanded} index={index} />
        ))}
      </div>
    </div>
  );
};

const BioGrid = ({ bios, blockConfig }) => {
  const breakpoints = useBreakpoint();

  return (
    <ScrollTriggerProvider options={{ start: 'top center', end: '+=200', pin: false }}>
      <BlockWrapper {...blockConfig}>
        {breakpoints.sm && <BiosMobile bios={bios} />}
        {!breakpoints.sm && <BiosDesk bios={bios} />}
      </BlockWrapper>
    </ScrollTriggerProvider>
  );
};

export default BioGrid;
