import React, { useState } from 'react';
import { Scrollama, Step } from 'react-scrollama';
import { motion, AnimatePresence } from 'framer-motion';
import AnchorLink from 'react-anchor-link-smooth-scroll';

// SVG
import ArrowDown from '../../../svg/arrowDown.svg';
import ArrowUp from '../../../svg/arrowUp.svg';

// Components
import { ImageModule } from '../../modules/imageModule';
import { BlockWrapper } from '../blockWrapper';
import { PortableTextBlock } from '../../sanity/portableTextBlock';

const imageVars = {
  enter: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
};
const cardVars = {
  enter: {
    opacity: 1,
    y: 0,
  },
  exit: {
    y: 20,
    opacity: 0,
  },
};

const ScrollerSection = ({ title, text, image, index, _key }) => {
  return (
    <div
      key={index}
      className="lg:grid lg:grid-cols-14  lg:grid-rows-section-scroller row-start-2 row-end-6"
    >
      <nav className="hidden lg:flex lg:flex-col lg:col-start-13 lg:col-end-14 lg:row-start-2 lg:self-end relative z-10 lg:justify-self-center mb-16 gap-y-4">
        <AnchorLink
          className="w-16 h-16 bg-mint-100 rounded-full flex items-center justify-center"
          href={`#step-${index - 1}-${_key}`}
        >
          <ArrowUp className="w-4 text-white fill-current" />
        </AnchorLink>
        <AnchorLink
          className="w-16 h-16 bg-mint-100 rounded-full flex items-center justify-center"
          href={`#step-${index + 1}-${_key}`}
        >
          <ArrowDown className="w-4 text-white fill-current" />
        </AnchorLink>
      </nav>
      {/* Image */}
      <motion.div
        initial="exit"
        animate="enter"
        exit="exit"
        variants={imageVars}
        transition={{ duration: 0.8 }}
        key={index}
        className="lg:col-start-5 lg:col-end-14 lg:row-start-2 relative"
      >
        <div className="aspect-w-1 aspect-h-1 w-full relative lg:rounded-3xl lg:overflow-hidden lg:w-full lg:h-full lg:absolute lg:top-0 lg:left-0 lg:aspect-none">
          {image && (
            <ImageModule
              {...image}
              className="w-full h-full object-cover absolute top-0 left-0 lg:h-full lg:w-full"
            />
          )}
        </div>
      </motion.div>
      <div className="px-gutter transform -translate-y-1/2 lg:row-start-2 lg:col-start-2 lg:col-end-7 lg:px-0 lg:-translate-y-0 lg:self-end lg:mb-16">
        {/* Card Box */}
        <div className="bg-grey-800 text-white rounded-3xl p-8 lg:py-16 lg:px-16 2xl:py-24">
          <motion.div
            variants={cardVars}
            initial="exit"
            animate="enter"
            exit="exit"
            transition={{ duration: 0.5 }}
            key={index}
          >
            <header className="flex gap-x-3 items-center w-full mb-3 lg:gap-x-6">
              <div>
                <span className="h-10 w-10 flex items-center justify-center rounded-full bg-peach-100 text-white lg:w-14 lg:h-14">
                  <span className="isH3">{index + 1}</span>
                </span>
              </div>
              <h4 className="text-h3-s lg:text-h2-s font-display">{title}</h4>
            </header>
            <PortableTextBlock text={text} className="prose" />
          </motion.div>
        </div>
      </div>
    </div>
  );
};

const SectionScroller = ({ blockConfig, title, items, _key }) => {
  const [currentStepIndex, setCurrentStepIndex] = useState(0);

  const onStepEnter = ({ data }) => {
    setCurrentStepIndex(data);
  };
  return (
    <BlockWrapper {...blockConfig}>
      <div className="grid grid-rows-1 grid-cols-1">
        {/* Sticky Roll */}
        <div className="w-100 sticky top-0 h-screen row-start-1 col-start-1 grid">
          <header className="py-10 md:pl-gutter row-start-1 row-end-1 self-center">
            <h2 className="isH2 text-center md:text-left">{title}</h2>
          </header>

          <AnimatePresence exitBeforeEnter initial={false}>
            <ScrollerSection {...items[currentStepIndex]} index={currentStepIndex} _key={_key} />
          </AnimatePresence>
        </div>
        <div className="row-start-1 col-start-1">
          <Scrollama onStepEnter={onStepEnter} offset={0.5}>
            {items.map((_, stepIndex) => (
              <Step data={stepIndex} key={stepIndex}>
                <div
                  style={{ height: '75vh' }}
                  id={`step-${stepIndex}-${_key}`}
                  // className="my-10"
                />
              </Step>
            ))}
          </Scrollama>
        </div>
      </div>
    </BlockWrapper>
  );
};

export default SectionScroller;
