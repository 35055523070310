import React from 'react';

import { ImageModule } from '../../modules/imageModule';
import { BlockWrapper } from '../blockWrapper';
import { PortableTextBlock } from '../../sanity/portableTextBlock';

const CenteredBanner = ({ blockConfig, bannerText, heading, image }) => {
  return (
    <BlockWrapper {...blockConfig}>
      <div className="grid grid-cols-14">
        {/* Spacer */}
        <div className="col-start-1 col-end-15 row-start-1 row-end-4 bg-mint-100 relative z-0 flex" />
        {/* Text */}
        <div className="pt-32 pb-24 text-white text-center col-start-2 col-end-14 row-start-2 relative z-10 max-w-2xl mx-auto lg:pt-64">
          <h1 className="isH1">{heading}</h1>
          <PortableTextBlock text={bannerText} className="prose mt-2 max-w-none" />
        </div>
        {/* Image */}
        <div className="col-start-2 col-end-14 row-start-3 row-end-5 xl:col-start-3 xl:col-end-13 xl:row-end-6">
          <div className="aspect-w-16 aspect-h-9 relative overflow-hidden  rounded-3xl lg:aspect-h-6">
            <ImageModule {...image} className="absolute top-0 left-0 w-full h-full object-cover" />
          </div>
        </div>
      </div>
    </BlockWrapper>
  );
};

export default CenteredBanner;
