import React from 'react';

import { CardMobile } from './cardMobile';
import { PortableTextBlock } from '../../../sanity/portableTextBlock';

export const MobileWalkthrough = ({ text, items }) => {
  return (
    <div className="px-gutter">
      {/* Cards */}
      <PortableTextBlock text={text} className="prose xl:prose-lg" />
      <div className="pt-10 grid gap-y-10">
        {items.map((item, index) => (
          <CardMobile {...item} index={index + 1} />
        ))}
      </div>
    </div>
  );
};
