import React from 'react';

import { useInView } from 'react-intersection-observer';

import { BlockWrapper } from '../blockWrapper';
import { VideoModule } from '../../modules';

const VideoBlock = ({ blockConfig, video }) => {
  return (
    <BlockWrapper {...blockConfig} className="grid grid-cols-14">
      <div className="col-start-2 col-end-14 lg:col-start-3 lg:col-end-13">
        <VideoModule {...video} />
      </div>
    </BlockWrapper>
  );
};

export default VideoBlock;
