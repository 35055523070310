import React from 'react';

import { BlockWrapper } from '../blockWrapper';
import { PageLink } from '../../ui/links';

const CallToAction = ({ blockConfig, title, link }) => {
  return (
    <BlockWrapper {...blockConfig} className="px-gutter">
      <div className="bg-peach-100 grid grid-cols-1 grid-rows-1 rounded-xl text-white relative z-10">
        <div className="text-white col-start-1 row-start-1 self-center text-center">
          <h2 className="isH2 text-center mb-8">{title}</h2>
          <PageLink {...link} className="btn rev mx-auto" />
        </div>
        <div className="w-full aspect-w-1 aspect-h-1 col-start-1 row-start-1 md:aspect-w-6 md:aspect-h-3 lg:aspect-w-8 2xl:aspect-w-10 relative z-5 pointer-events-none" />
      </div>
    </BlockWrapper>
  );
};

export default CallToAction;
