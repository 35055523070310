import React from 'react';

import { BlockWrapper } from '../blockWrapper';
import { PortableTextBlock } from '../../sanity/portableTextBlock';

const FeatureText = ({ blockConfig, text, title }) => {
  return (
    <BlockWrapper {...blockConfig} className="px-gutter grid gap-y-10 md:grid-cols-2 md:gap-x-20">
      <h2 className="isH2">{title}</h2>
      <PortableTextBlock text={text} className="prose xl:prose-lg max-w-none" />
    </BlockWrapper>
  );
};

export default FeatureText;
