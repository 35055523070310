import React, { useState } from 'react';
import { AnimatePresence, motion, AnimateSharedLayout } from 'framer-motion';

import ChevronRight from '../../../svg/chevron-r.svg';

import { BlockWrapper } from '../blockWrapper';
import { PortableTextBlock } from '../../sanity/portableTextBlock';
import { accordionReveal, defaultTransition } from '../../../lib/animation';
import { PageLink } from '../../ui/links';

const FaqRow = ({ _key, title, text, expanded, setExpanded }) => {
  const isOpen = _key === expanded;
  return (
    <motion.div layout className="text-grey-600">
      <h4
        className="font-display-med py-4 border-b border-current flex items-center justify-between isH4 cursor-pointer"
        onClick={() => setExpanded(isOpen ? false : _key)}
      >
        {title}
        <motion.div
          animate={{ rotate: isOpen ? 90 : 0 }}
          transition={{ ...defaultTransition }}
          className="text-peach-100"
        >
          <ChevronRight className="fill-current text-current w-2" />
        </motion.div>
      </h4>
      <AnimatePresence initial={false}>
        {isOpen && (
          <motion.div
            variants={accordionReveal}
            initial="closed"
            animate="open"
            exit="closed"
            className="overflow-hidden"
          >
            <PortableTextBlock text={text} className="prose py-4 max-w-none" />
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

const FaqSection = ({ title, items }) => {
  const [expanded, setExpanded] = useState(0);
  return (
    <div className="col-start-2 col-end-14 md:col-start-3 md:col-end-13 lg:col-start-4 lg:col-end-12">
      <h2 className="isH3 py-10">{title}</h2>
      <AnimateSharedLayout>
        <div className="grid gap-y-4">
          {items.map((item) => (
            <FaqRow {...item} expanded={expanded} setExpanded={setExpanded} />
          ))}
        </div>
      </AnimateSharedLayout>
    </div>
  );
};

const Faqs = ({ blockConfig, sections, contactLink, helpText }) => {
  return (
    <BlockWrapper {...blockConfig}>
      <div className="grid grid-cols-14 gap-y-10">
        {sections.map((item) => (
          <FaqSection {...item} />
        ))}
        {/* CTA */}
        <div className="col-start-2 col-end-14 md:col-start-3 md:col-end-13 lg:col-start-4 lg:col-end-12 mt-10">
          <div className="flex flex-col text-center items-center justify-center gap-4 bg-mint-25 py-20 px-10 rounded-3xl lg:flex-row lg:text-left">
            <h6 className="text-grey-800 text-xl">{helpText}</h6>
            <PageLink {...contactLink} className="btn-lg" />
          </div>
        </div>
      </div>
    </BlockWrapper>
  );
};

export default Faqs;
