import React from 'react';

import cx from 'classnames';
import { ImageModule } from '../../../modules/imageModule';

import { PortableTextBlock } from '../../../sanity/portableTextBlock';

export const CardDesktop = ({ text, image, index, className }) => {
  const cardClasses = cx(className, {
    'bg-mint-25 rounded-2xl sticky top-10 overflow-hidden transform transition-all duration-500': true,
  });

  return (
    <div className={cardClasses}>
      <div className="flex">
        {/* Number */}
        <div className="w-1/3">
          <span className="h-10 w-10 flex items-center justify-center rounded-full bg-peach-100 text-white mx-auto mt-10">
            {index}
          </span>
        </div>
        {/* Image */}
        <div className="w-2/3">
          <div className="aspect-w-4 aspect-h-5 relative overflow-hidden rounded-bl-3xl">
            {image && (
              <ImageModule
                {...image}
                className="h-full w-full absolute top-0 left-0 object-cover"
              />
            )}
          </div>
        </div>
      </div>
      {/* Text */}
      <PortableTextBlock text={text} className="px-10 prose py-10 text-gray-600" />
    </div>
  );
};
