const defaultTransition = {
  duration: 0.3,
  ease: 'linear',
};

const accordionReveal = {
  open: { opacity: 1, height: 'auto', transition: { ...defaultTransition } },
  closed: { opacity: 0, height: '0px', transition: { ...defaultTransition } },
};

export { defaultTransition, accordionReveal };
