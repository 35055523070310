import React, { useRef, useState, useLayoutEffect } from 'react';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import cx from 'classnames';
import { ImageModule } from '../../../modules/imageModule';

import { PortableTextBlock } from '../../../sanity/portableTextBlock';

export const CardMobile = ({ text, image, index }) => {
  const [isTop, setTop] = useState(false);
  const [elementTop, setElementTop] = useState(0);
  const ref = useRef(null);
  const cardClasses = cx({
    'bg-mint-25 rounded-2xl sticky top-10 overflow-hidden transform transition-all duration-500': true,
    'scale-90': isTop,
  });

  useLayoutEffect(() => {
    const element = ref.current;
    setElementTop(element.offsetTop);
  }, [ref]);

  useScrollPosition(
    ({ currPos }) => {
      console.log(currPos.y);
      setTop(currPos.y < -(elementTop - 100));
    },
    [isTop, elementTop]
  );

  return (
    <div className={cardClasses} ref={ref}>
      <div className="flex">
        {/* Number */}
        <div className="w-1/3">
          <span className="h-10 w-10 flex items-center justify-center rounded-full bg-peach-100 text-white mx-auto mt-10">
            {index}
          </span>
        </div>
        {/* Image */}
        <div className="w-2/3">
          <div className="aspect-w-4 aspect-h-5 relative overflow-hidden rounded-bl-3xl">
            {image && (
              <ImageModule
                {...image}
                className="h-full w-full absolute top-0 left-0 object-cover"
              />
            )}
          </div>
        </div>
      </div>
      {/* Text */}
      <PortableTextBlock text={text} className="px-10 prose py-10 text-gray-600" />
    </div>
  );
};
