import React from 'react';

import { useBreakpoint } from 'gatsby-plugin-breakpoints';

// Hooks
import { useMediaQuery } from '@react-hook/media-query';
import { ImageModule } from '../modules/imageModule';

export const ResponsiveImage = ({ imageLarge, imageSmall, ...rest }) => {
  const breakpoints = useBreakpoint();

  const activeImage = breakpoints.sm ? imageSmall : imageLarge;
  return activeImage && <ImageModule image={activeImage} {...rest} />;
};
