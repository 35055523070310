import React from 'react';
import { useTransform, motion } from 'framer-motion';

import Wedge from '../../../svg/wedge.svg';
import { PortableTextBlock } from '../../sanity/portableTextBlock';
import { BlockWrapper } from '../blockWrapper';
import { ScrollTriggerProvider, useScrollTrigger } from '../../animation/scrollTriggerProvider';

const GridItem = ({ title, text }) => {
  return (
    <div>
      <div className="flex gap-x-4 items-center mb-4">
        <Wedge className="w-6 text-mint-100 fill-current" />
        <h4 className="isH4">{title}</h4>
      </div>
      <PortableTextBlock text={text} className="prose" />
    </div>
  );
};

const GridTextInner = ({ blockConfig, items, title }) => {
  const position = useScrollTrigger();
  const opacity = useTransform(position, [0, 1], [0, 1]);
  const y = useTransform(position, [0, 1], [50, 0]);
  const y2 = useTransform(position, [0, 1], [100, 0]);
  return (
    <BlockWrapper {...blockConfig}>
      <motion.h2 className="isH2 text-center" style={{ opacity, y }}>
        {title}
      </motion.h2>
      <motion.div
        className="px-gutter py-16 grid gap-y-10 md:grid-cols-2 md:gap-10 lg:grid-cols-3 lg:py-24 xl:gap-16"
        style={{ opacity, y: y2 }}
      >
        {items.map((item) => (
          <GridItem {...item} />
        ))}
      </motion.div>
    </BlockWrapper>
  );
};

const GridText = (props) => {
  return (
    <ScrollTriggerProvider options={{ start: 'top center', end: '+=200', pin: false }}>
      <GridTextInner {...props} />
    </ScrollTriggerProvider>
  );
};

export default GridText;
