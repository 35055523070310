import React from 'react';

import { BlockWrapper } from '../blockWrapper';

const TextBanner = ({ blockConfig, heading }) => {
  return (
    <BlockWrapper
      {...blockConfig}
      className="px-gutter bg-mint-100 text-white text-center py-32 lg:py-48"
    >
      <h1 className="isH1">{heading}</h1>
    </BlockWrapper>
  );
};

export default TextBanner;
