import React from 'react';
import cx from 'classnames';

export const BlockWrapper = ({ children, className, paddingTop, paddingBottom, slug, bgColor }) => {
  const paddingClasses = cx({
    'pt-12 md:pt-40': paddingTop === 'small',
    'pt-12 md:pt-64': paddingTop === 'medium',
    'pt-12 md:pt-96': paddingTop === 'large',
    'pb-12 md:pb-40': paddingBottom === 'small',
    'pb-12 md:pb-64': paddingBottom === 'medium',
    'pb-12 md:pb-96': paddingBottom === 'large',
  });
  return (
    <section
      className={`${className} ${paddingClasses}`}
      id={slug && slug.current}
      style={{ background: bgColor && bgColor.value }}
    >
      {children}
    </section>
  );
};
