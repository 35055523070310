import React from 'react';
import PropTypes from 'prop-types';

import {
  HomeBanner,
  TextBlock,
  ImageText,
  ImageBlock,
  VideoBlock,
  ImageSlider,
  CardWalkthrough,
  CallToAction,
  SliceBanner,
  SectionScroller,
  FeatureText,
  BioGrid,
  GridText,
  ContactBanner,
  ContactForm,
  CenteredBanner,
  Faqs,
  SignUpBanner,
  TextBanner,
} from './blocks';

const BlockZone = ({ blocks }) => {
  const block = blocks.map((block) => {
    switch (block._type) {
      case 'homeBanner':
        return <HomeBanner {...block} />;
      case 'textBanner':
        return <TextBanner {...block} />;
      case 'signUpBanner':
        return <SignUpBanner {...block} />;
      case 'contactBanner':
        return <ContactBanner {...block} />;
      case 'textBlock':
        return <TextBlock {...block} />;
      case 'imageText':
        return <ImageText {...block} />;
      case 'cardWalkthrough':
        return <CardWalkthrough {...block} />;
      case 'imageBlock':
        return <ImageBlock {...block} />;
      case 'videoBlock':
        return <VideoBlock {...block} />;
      case 'imageSlider':
        return <ImageSlider {...block} />;
      case 'callToAction':
        return <CallToAction {...block} />;
      case 'sliceBanner':
        return <SliceBanner {...block} />;
      case 'sectionScroller':
        return <SectionScroller {...block} />;
      case 'featureText':
        return <FeatureText {...block} />;
      case 'bioGrid':
        return <BioGrid {...block} />;
      case 'gridText':
        return <GridText {...block} />;
      case 'contactForm':
        return <ContactForm {...block} />;
      case 'centeredBanner':
        return <CenteredBanner {...block} />;
      case 'faqs':
        return <Faqs {...block} />;
      default:
        return null;
    }
  });
  return <>{block}</>;
};

BlockZone.propTypes = {
  blocks: PropTypes.array.isRequired,
};

export default BlockZone;
