import React, { useRef, useState, useLayoutEffect, useCallback, useEffect } from 'react';

import { motion, useTransform } from 'framer-motion';
import ResizeObserver from 'resize-observer-polyfill';

import { CardDesktop } from './cardDesktop';
import { PortableTextBlock } from '../../../sanity/portableTextBlock';
import { ScrollTriggerProvider, useScrollTrigger } from '../../../animation/scrollTriggerProvider';

const ScrollerInner = ({ text, items }) => {
  const scrollRef = useRef(null);
  const ghostRef = useRef(null);

  const progress = useScrollTrigger();
  const [scrollRange, setScrollRange] = useState(0);
  const [viewportW, setViewportW] = useState(0);

  useLayoutEffect(() => {
    scrollRef && setScrollRange(scrollRef.current.scrollWidth);
  }, [scrollRef]);

  const onResize = useCallback((entries) => {
    for (let entry of entries) {
      setViewportW(entry.contentRect.width);
    }
  }, []);

  useLayoutEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => onResize(entries));
    resizeObserver.observe(ghostRef.current);
    return () => resizeObserver.disconnect();
  }, [onResize]);

  const carouselProgress = useTransform(progress, [0, 1], [0, -scrollRange + viewportW]);
  return (
    <div className="overflow-hidden">
      <div className="sticky top-0">
        <motion.div
          className="flex h-screen items-center w-max px-gutter trans-change"
          style={{ x: carouselProgress }}
          ref={scrollRef}
          transition={{ duration: 0.1, ease: 'linear' }}
        >
          <div className="flex relative gap-x-10">
            <div style={{ flex: '0 0 auto' }} className="w-screen-1/3">
              <PortableTextBlock text={text} className="prose xl:prose-lg" />
            </div>
            {items.map((item, index) => (
              <CardDesktop
                {...item}
                index={index + 1}
                className="w-screen-1/4 last:mr-gutter "
                style={{ flex: '0 0 auto' }}
              />
            ))}
          </div>
        </motion.div>
      </div>
      <div ref={ghostRef} className="ghost" />
    </div>
  );
};

export const DesktopWalkthrough = (props) => {
  return (
    <ScrollTriggerProvider>
      <ScrollerInner {...props} />
    </ScrollTriggerProvider>
  );
};
