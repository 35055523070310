import React from 'react';
import cx from 'classnames';
import { useViewportScroll, motion, useTransform, useMotionValue } from 'framer-motion';

import { BlockWrapper } from '../blockWrapper';
import { PortableTextBlock } from '../../sanity/portableTextBlock';
import { ResponsiveImage } from '../../media/responsiveImage';

const SliceBanner = ({ bannerText, heading, blockConfig, image, mobileOverflow, reverseSlice }) => {
  const { scrollY } = useViewportScroll();
  const y1 = useTransform(scrollY, [0, 400], [0, -50]);

  return (
    <BlockWrapper {...blockConfig} className="w-full">
      <div
        className={cx('grid grid-cols-14 md:grid-rows-home-banner-desk w-full ', {
          'grid-rows-home-banner': mobileOverflow,
          'pb-24': !mobileOverflow,
        })}
      >
        {/* BG */}
        <div className="bg-grey-800 row-start-1 row-end-3 col-start-1 col-end-15 relative z-0" />
        {/* Image */}
        <div className="col-start-5 col-end-15 row-start-1 relative z-10 md:col-start-7 md:row-end-4 overflow-hidden">
          <div className="w-full relative transform translate-x-10 lg:translate-x-20 -translate-y-10">
            <svg className="w-full" viewBox="0 0 328.86 332.21" className="">
              <clipPath id="my-clip-path" clipPathUnits="objectBoundingBox">
                {reverseSlice && (
                  <path d="M0.986,0.202 c0.056,-0.12,-0.07,-0.245,-0.19,-0.187 L0.081,0.357 C-0.008,0.399,-0.027,0.516,0.043,0.585 l0.38,0.374 a0.142,0.14,0,0,0,0.229,-0.041"></path>
                )}
                {!reverseSlice && (
                  <path d="M0.016,0.203 c-0.056,-0.12,0.07,-0.245,0.19,-0.187 L0.922,0.358 c0.089,0.042,0.108,0.159,0.038,0.228 L0.58,0.96 a0.142,0.14,0,0,1,-0.229,-0.041"></path>
                )}
              </clipPath>
            </svg>
            <motion.div
              style={{ clipPath: 'url(#my-clip-path)' }}
              className="w-full absolute top-0 left-0 h-full"
            >
              <motion.div
                className="w-full absolute top-0 left-0"
                style={{ y: y1, height: '110%' }}
              >
                {image && (
                  <ResponsiveImage
                    {...image}
                    className="absolute w-full h-full top-0 left-0 object-cover"
                  />
                )}
              </motion.div>
            </motion.div>
          </div>
        </div>
        {/* Text */}
        <div className="col-start-2 col-end-14 row-start-2 text-white relative z-10 pb-16 md:col-end-7 md:self-end 2xl:self-center 2xl:row-start-1 xl:row-end-3 xl:pb-0">
          <h1 className="isH1 mb-2">{heading}</h1>
          <PortableTextBlock text={bannerText} />
        </div>
        {/* Spacer */}
        <div className="row-start-3 h-24 bg-grey-800 col-start-1 col-end-15" />
      </div>
    </BlockWrapper>
  );
};

export default SliceBanner;
