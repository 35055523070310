import React from 'react';
import { useViewportScroll, motion, useTransform, useMotionValue } from 'framer-motion';

import ArrowDown from '../../../svg/arrowDown.svg';

import { BlockWrapper } from '../blockWrapper';
import { PortableTextBlock } from '../../sanity/portableTextBlock';
import { ResponsiveImage } from '../../media/responsiveImage';

const ContactDetail = ({ title, text }) => {
  return (
    <div className="">
      <h4 className="isH4 text-mint-50">{title}</h4>
      <PortableTextBlock text={text} className="text-sm leading-5 pt-2" />
    </div>
  );
};

const ContactBanner = ({ blockConfig, heading, subHeading, image, details }) => {
  const { scrollY } = useViewportScroll();
  const y1 = useTransform(scrollY, [0, 400], [0, -50]);
  return (
    <BlockWrapper {...blockConfig}>
      <div className="grid grid-cols-14 bg-grey-800">
        {/* Image */}
        <div className="col-start-5 col-end-15 row-start-1 relative z-10 md:col-start-7 md:row-end-4 overflow-hidden">
          <div className="w-full transform relative translate-x-10 lg:translate-x-20 -translate-y-10">
            <svg className="w-full" viewBox="0 0 328.86 332.21">
              <clipPath id="my-clip-path" clipPathUnits="objectBoundingBox">
                <path d="M0.986,0.202 c0.056,-0.12,-0.07,-0.245,-0.19,-0.187 L0.081,0.357 C-0.008,0.399,-0.027,0.516,0.043,0.585 l0.38,0.374 a0.142,0.14,0,0,0,0.229,-0.041"></path>
              </clipPath>
            </svg>
            <motion.div
              style={{ clipPath: 'url(#my-clip-path)' }}
              className="w-full absolute top-0 left-0 h-full"
            >
              <motion.div
                className="w-full absolute top-0 left-0"
                style={{ y: y1, height: '110%' }}
              >
                {image && (
                  <ResponsiveImage
                    {...image}
                    className="absolute w-full h-full top-0 left-0 object-cover"
                  />
                )}
              </motion.div>
            </motion.div>
          </div>
        </div>
        {/* Text */}
        <div className="col-start-2 col-end-14 row-start-2 text-white relative z-10 pb-16 md:col-end-7">
          <h1 className="isH1 mb-2">{heading}</h1>
          <h3 className="isH3">{subHeading}</h3>
          <div className="border-t border-mint-50 mt-10 pt-10 grid grid-cols-2">
            {details.map((item) => (
              <ContactDetail {...item} />
            ))}
          </div>
        </div>
        {/* Or Message */}
        <div className="row-start-4 col-start-2 col-end-14 text-center text-white flex flex-col gap-y-4 pb-16">
          <h3 className="isH3">Or send us a message</h3>
          <div className="w-10 h-10 rounded-full bg-white flex items-center justify-center mx-auto">
            <ArrowDown className="text-mint-50 w-4 fill-current" />
          </div>
        </div>
      </div>
    </BlockWrapper>
  );
};

export default ContactBanner;
