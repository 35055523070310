import React from 'react';
import Lottie from 'react-lottie-player';

import Spinner from '../../../svg/spinner.svg';
import BorderImage from '../../../svg/bannerBorder.svg';

import SpinnerJson from '../../slice.json';

import { BlockWrapper } from '../blockWrapper';
import { SignUpForm } from '../../forms/signUpForm';

const SignUpBanner = ({ heading, subHeading, blockConfig }) => {
  return (
    <BlockWrapper {...blockConfig}>
      <div className="grid grid-cols-14 grid-rows-home-banner md:grid-rows-home-banner-desk">
        {/* BG */}
        <div className="bg-grey-800 row-start-1 row-end-3 col-start-1 col-end-15 relative z-0" />
        {/*  Form And Text */}
        <div className="col-start-2 col-end-14 row-start-2 relative z-10 pt-32 pb-16 lg:col-start-4 lg:col-end-12 lg:pt-40">
          {/* Spinner */}
          <div className="h-64 lg:h-96 mb-10">
            <Lottie play loop animationData={SpinnerJson} className="w-64 mx-auto lg:w-96" />
          </div>
          {/* Text */}
          <div className="text-white text-center">
            <h1 className="isH2 mb-4 ">{heading}</h1>
            <h3 className="isH3">{subHeading}</h3>
          </div>
          {/* Sign Up Form */}
          <SignUpForm />
        </div>
        {/* Border SVG */}
        <div className="col-start-1 col-end-15 row-start-3 ">
          <BorderImage />
        </div>
      </div>
    </BlockWrapper>
  );
};

export default SignUpBanner;
