import React from 'react';
import { motion } from 'framer-motion';
import cx from 'classnames';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useLocation } from '@reach/router';

import { BlockWrapper } from '../blockWrapper';

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
}

const ContactForm = ({ blockConfig, title }) => {
  const inputClasses = 'border w-full border-grey-400 p-3 rounded-lg';
  const labelClasses = 'text-grey-800 flex';
  const { pathname } = useLocation();

  return (
    <BlockWrapper {...blockConfig} className="px-gutter">
      <Formik
        initialValues={{
          firstName: '',
          lastName: '',
          company: '',
          email: '',
          message: '',
        }}
        validate={(values) => {
          const errors = {};
          if (!values.firstName) {
            errors.firstName = 'First Name is Required';
          }
          if (!values.message) {
            errors.message = 'Message is Required';
          }

          if (!values.email) {
            errors.email = 'Email address is Required';
          } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
            errors.email = 'Invalid email address';
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting, setStatus }) => {
          fetch(pathname || '/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: encode({
              'form-name': `enquiry-form`,
              ...values,
            }),
          })
            .then(() => {
              setSubmitting(false);
              setStatus('success');
            })
            .catch((error) => alert(error));
        }}
      >
        {({
          onChange,
          isSubmitting,
          status,
          values,
          /* and other goodies */
        }) => (
          <Form name="enquiry-form" data-netlify="true" data-netlify-honeypot="bot-field">
            {/* Hidden Fields for netlify */}
            <input type="hidden" name="form-name" value="enquiry-form" />
            <p hidden>
              <label>
                <input name="bot-field" onChange={onChange} />
              </label>
            </p>
            {status === 'success' ? (
              <div className="col-span-full text-left">
                <motion.h2
                  className="font-display text-2xl"
                  initial={{ y: 20, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                >
                  Thank you we will be in touch.
                </motion.h2>
              </div>
            ) : (
              <>
                <div className="grid gap-y-6 md:grid-cols-2 md:gap-x-10 max-w-5xl mx-auto">
                  <div>
                    <div className="flex justify-between items-center mb-4">
                      <label className={labelClasses}>First Name</label>
                      <ErrorMessage name="firstName" className="text-sm" component="div" />
                    </div>
                    <Field type="text" name="firstName" className={inputClasses} />
                  </div>
                  <div>
                    <div className="flex justify-between items-center mb-4">
                      <label className={labelClasses}>Last Name</label>
                    </div>
                    <Field type="text" name="lastName" className={inputClasses} />
                  </div>
                  <div>
                    <div className="flex justify-between items-center mb-4">
                      <label className={labelClasses}>Email</label>
                      <ErrorMessage name="email" className="text-sm" component="div" />
                    </div>
                    <Field
                      type="email"
                      name="email"
                      className={inputClasses}
                      placeholder="youremail@email.com"
                    />
                  </div>
                  <div>
                    <div className="flex justify-between items-center mb-4">
                      <label className={labelClasses}>Company Name</label>
                    </div>
                    <Field type="text" name="company" className={inputClasses} />
                  </div>
                  <div className="md:col-span-2">
                    <div className="flex justify-between items-center mb-4">
                      <label className={labelClasses}>Message</label>
                      <ErrorMessage name="message" className="text-sm" component="div" />
                    </div>
                    <Field
                      type="text"
                      name="message"
                      className={inputClasses}
                      placeholder="Your Message"
                      component="textarea"
                    />
                  </div>
                  <button
                    type="submit"
                    className={cx('w-full btn justify-self-center md:col-span-2 md:w-auto', {
                      'animate-pulse': isSubmitting,
                    })}
                    disabled={isSubmitting}
                  >
                    Submit
                  </button>
                </div>
              </>
            )}
          </Form>
        )}
      </Formik>
    </BlockWrapper>
  );
};

export default ContactForm;
