import React from 'react';
import cx from 'classnames';
import { useTransform, motion } from 'framer-motion';

import { BlockWrapper } from '../blockWrapper';

import { PortableTextBlock } from '../../sanity/portableTextBlock';

import { ImageModule } from '../../modules/imageModule';

import { ScrollTriggerProvider, useScrollTrigger } from '../../animation/scrollTriggerProvider';

const InnerComponent = ({ text, image, layout, blockConfig }) => {
  const position = useScrollTrigger();
  const imageClasses = cx({
    'col-start-2 col-end-14 row-start-1': true,
    'md:col-end-8 lg:col-start-3': layout === 'left',
    'md:col-start-8 lg:col-end-13': layout === 'right',
  });
  const textClasses = cx({
    'col-start-2 col-end-14 md:self-center': true,
    'md:col-start-9 lg:col-end-13': layout === 'left',
    'md:col-end-7 lg:col-start-3': layout === 'right',
  });

  const opacity = useTransform(position, [0, 1], [0, 1]);
  const y = useTransform(position, [0, 1], [50, 0]);

  return (
    <BlockWrapper className="grid grid-cols-14 gap-y-10" {...blockConfig}>
      <div className={imageClasses}>
        <motion.div
          className="aspect-w-5 aspect-h-4 relative rounded-3xl overflow-hidden md:aspect-w-1 md:aspect-h-1 "
          style={{ opacity, y, willChange: 'scroll-position' }}
        >
          {image && (
            <ImageModule {...image} className="absolute top-0 left-0 object-cover w-full h-full" />
          )}
        </motion.div>
      </div>
      <motion.div className={textClasses} style={{ opacity }}>
        {text && (
          <PortableTextBlock text={text} className="prose xl:prose-lg max-w-none text-gray-800 " />
        )}
      </motion.div>
    </BlockWrapper>
  );
};

const ImageText = (props) => {
  return (
    <ScrollTriggerProvider options={{ start: 'top center', end: '+=200', pin: false }}>
      <InnerComponent {...props} />
    </ScrollTriggerProvider>
  );
};

export default ImageText;
