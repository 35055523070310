import React from 'react';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import BannerBorder from '../../../../svg/bannerBorderRev.svg';

import { BlockWrapper } from '../../blockWrapper';
import { MobileWalkthrough } from './mobileWalkthrough';
import { DesktopWalkthrough } from './desktopWalkthrough';

const CardWalkthrough = ({ blockConfig, ...props }) => {
  const breakpoints = useBreakpoint();
  return (
    <BlockWrapper {...blockConfig}>
      <BannerBorder className="text-peach-5 fill-current" />
      <div className="bg-peach-5 py-16 md:py-0">
        {breakpoints.sm && <MobileWalkthrough {...props} />}
        {!breakpoints.sm && <DesktopWalkthrough {...props} />}
      </div>
    </BlockWrapper>
  );
};

export default CardWalkthrough;
