import React from 'react';
import { useViewportScroll, motion, useTransform, useMotionValue } from 'framer-motion';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

// SVG
import BorderImage from '../../../svg/bannerBorder.svg';
import Wedge from '../../../svg/wedge.svg';

import { ImageModule } from '../../modules/imageModule';

const BannerCard = ({ image, delay }) => {
  const breakpoints = useBreakpoint();
  const spread = breakpoints.sm ? 10 : 30;

  return (
    <motion.div
      style={{ flex: '0 0 auto' }}
      className="w-1/3 aspect-w-8 aspect-h-5 relative rounded-xl overflow-hidden md:aspect-w-5 md:aspect-h-2"
      animate={{ y: [spread, -spread] }}
      transition={{
        duration: 2.2,
        delay: 0.5 * delay,
        yoyo: Infinity,
      }}
    >
      {image && (
        <ImageModule {...image} className="absolute top-0 left-0 w-full h-full object-cover" />
      )}
    </motion.div>
  );
};

const HomeBanner = ({ imageOne, imageTwo, imageThree, heading, subHeading }) => {
  const breakpoints = useBreakpoint();

  const { scrollY } = useViewportScroll();

  const x = useTransform(scrollY, [0, 600], [`${breakpoints.sm ? '5%' : '20%'}`, '0%']);
  return (
    <div className="text-white grid grid-rows-home-banner md:grid-rows-home-banner-desk">
      {/* Images */}
      <div className="pt-32 pb-10 relative z-50 row-start-1 bg-grey-800 w-full md:row-start-2 md:pt-10 md:pb-20 lg:pt-16 overflow-hidden">
        <motion.div className="flex gap-x-5 lg:gap-x-10" style={{ x }}>
          {/* Image One */}
          <BannerCard image={imageOne} delay={0} />
          {/* Image Two */}
          <BannerCard image={imageTwo} delay={1} />
          {/* Image Two */}
          <BannerCard image={imageThree} delay={2} />
        </motion.div>
      </div>
      {/* Banner Text */}
      <div className="px-gutter pt-10 pb-20 row-start-2 bg-grey-800 md:row-start-1 md:pt-32 lg:pt-64 ">
        <h1 className="isH1">{heading}</h1>
        <h3 className="isH3 mt-4">{subHeading}</h3>
        <div className="mt-10 hidden md:flex">
          <Wedge className="w-12 text-peach-100 fill-current" />
        </div>
      </div>
      {/* Border SVG */}
      <div className="w-full row-start-3 ">
        <BorderImage />
      </div>
    </div>
  );
};

export default HomeBanner;
