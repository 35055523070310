import React from 'react';
import cx from 'classnames';

import { ImageModule } from '../../modules/imageModule';
import { BlockWrapper } from '../blockWrapper';

const ImageBlock = ({ blockConfig, image, layout }) => {
  return (
    <BlockWrapper {...blockConfig} className="grid grid-cols-14">
      <div
        className={cx('rounded-3xl overflow-hidden', {
          'col-start-2 col-end-14': layout === 'center',
          'col-start-1 col-end-15': layout === 'full',
        })}
      >
        <ImageModule {...image} />
      </div>
    </BlockWrapper>
  );
};

export default ImageBlock;
